const xs = "540px";
const sm = "768px";
const md = "1024px";
const lg = "1200px";
const xlg = "1400px";
const xxlg = "1600px";
const xxxlg = "1920px";

export default {
  xs,
  sm,
  md,
  lg,
  xlg,
  xxlg,
  xxxlg,
};
